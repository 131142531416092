<template></template><template>
  <div>
    <div class="top" style="margin: 20px 0; display: flex">
      <el-button type="primary" @click="addDialog()" style="margin-right: 25px"
        >新增</el-button
      >
      <el-input
        type="text"
        style="width: 215px"
        placeholder="请输入搜索内容"
        v-model.trim="queryList.keyword"
        clearable
      ></el-input>
      <el-button type="primary" style="margin-right: 20px" @click="changeCond"
        >搜索</el-button
      >
      <el-radio-group
        @change="changeCondition"
        v-model="queryList.status"
        style="display: flex; align-items: center"
      >
        <el-radio :label="1">正常推广</el-radio>
        <el-radio :label="0">冻结推广</el-radio>
      </el-radio-group>
      <div style="position: relative; margin-left: 30px">
        <el-button
          type="primary"
          style="padding-right: 30px"
          @click="changPrice"
        >
          广告出价
        </el-button>
        <div style="position: absolute; right: 10px; top: 0; height: 100%">
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              cursor: pointer;
            "
          >
            <img
              v-if="queryList.orderByPrice === 1"
              src="./icon/up-arrow-light.png"
              alt=""
              style="width: 14px; display: block; transform: translateY(2px)"
              @click="changPriceUp"
            />
            <img
              v-else
              src="./icon/up-arrow-white.png"
              alt=""
              style="width: 14px; display: block; transform: translateY(2px)"
              @click="changPriceUp"
            />
            <img
              v-if="queryList.orderByPrice === 2"
              src="./icon/down-arrow-light.png"
              alt=""
              style="width: 14px; transform: translateY(-2px)"
              @click="changPriceDown"
            />
            <img
              v-else
              src="./icon/down-arrow-white.png"
              alt=""
              style="width: 14px; transform: translateY(-2px)"
              @click="changPriceDown"
            />
          </div>
        </div>
      </div>
      <div style="position: relative; margin-left: 30px">
        <el-button type="primary" style="padding-right: 30px" @click="changNum">
          广告转化数
        </el-button>
        <div style="position: absolute; right: 10px; top: 0; height: 100%">
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              cursor: pointer;
            "
          >
            <img
              v-if="queryList.orderByNum === 1"
              src="./icon/up-arrow-light.png"
              alt=""
              style="width: 14px; display: block; transform: translateY(2px)"
              @click="changNumUp"
            />
            <img
              v-else
              src="./icon/up-arrow-white.png"
              alt=""
              style="width: 14px; display: block; transform: translateY(2px)"
              @click="changNumUp"
            />
            <img
              v-if="queryList.orderByNum === 2"
              src="./icon/down-arrow-light.png"
              alt=""
              style="width: 14px; transform: translateY(-2px)"
              @click="changNumDown"
            />
            <img
              v-else
              src="./icon/down-arrow-white.png"
              alt=""
              style="width: 14px; transform: translateY(-2px)"
              @click="changNumDown"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="dialogTableVisible" width="1100px">
      <el-form
        :rules="rules"
        size="small"
        :model="dataList"
        ref="dataList"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div style="display: flex">
          <el-form-item
            label="子计划"
            style="width: 318px; margin-right: 15px"
            prop="institutionName"
          >
            <el-input
              v-model="dataList.institutionName"
              placeholder="请输入子计划名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="advBid"
            style="width: 218px; margin-right: 15px"
            label-width="0"
          >
            <el-input
              type="number"
              v-model="dataList.advBid"
              placeholder="请输入出价"
            ></el-input>
          </el-form-item>
        </div>
        <div style="width: 100%">
          <el-form-item label="地区配置" style="width: 100%" prop="region">
            <el-cascader
              :options="options"
              :props="props"
              @change="regionChange"
              clearable
              collapse-tags
              v-model="dataList.region"
            ></el-cascader>
          </el-form-item>
        </div>
        <el-form-item label="" style="width: 100%">
          <div :class="{ 'hidden-line-5': areaConfigHide }">
            <div
              :key="item.city"
              style="display: inline-block; margin: 2px 0; width: 18%"
              v-for="item in regionsPrivce"
            >
              <span
                v-if="item.city.length < 6"
                style="
                  display: inline-block;
                  width: 70px;
                  text-align: right;
                  font-size: 12px;
                "
              >
                {{ item.city }}
              </span>
              <el-tooltip
                v-else
                effect="dark"
                :content="item.city"
                placement="top"
              >
                <span
                  style="
                    display: inline-block;
                    width: 70px;
                    text-align: right;
                    font-size: 12px;
                  "
                >
                  {{ item.city.slice(0, 4) }}...
                </span>
              </el-tooltip>
              <el-input
                placeholder="限量"
                style="width: 75px; margin-left: 8px"
                v-model="item.limitNum"
              ></el-input>
            </div>
          </div>
          <div v-show="regionsPrivce.length > 20" class="show-hide">
            <span v-show="areaConfigHide" @click="areaConfigHide = false"
              >展开</span
            >
            <span v-show="!areaConfigHide" @click="areaConfigHide = true"
              >收起</span
            >
          </div>
        </el-form-item>
        <el-form-item label="年龄" style="width: 100%" prop="ageStr">
          <el-radio-group v-model="dataList.ageStr">
            <el-radio label="20-59"></el-radio>
            <el-radio label="25-55"></el-radio>
            <el-radio label="25-45"></el-radio>
            <el-radio label="25-59"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="额度" style="width: 100%">
          <el-radio-group v-model="dataList.moneyStr">
            <el-radio label="0">无限制</el-radio>
            <el-radio label="2-5万"></el-radio>
            <el-radio label="5万以上"></el-radio>
          </el-radio-group>
        </el-form-item>
        <div style="display: flex; width: 100%">
          <el-form-item label="主条件" prop="type">
            <el-select
              v-model="dataList.type"
              placeholder="选择主条件"
              @change="changeMainCond"
            >
              <el-option
                :key="item.typeValue"
                :label="item.typeValue"
                :value="item.type"
                v-for="item in optionType"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label=""
            prop="mainCondStr"
            label-width="0"
            v-show="dataList.type"
          >
            <el-radio-group v-model="dataList.mainCondStr">
              <el-radio
                v-for="item in mainCondList"
                :label="item"
                style="margin-right: 8px"
              ></el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="逾期" style="width: 100%">
          <el-radio-group v-model="dataList.overdueState">
            <el-radio :label="0">无限制</el-radio>
            <el-radio :label="1">逾期用户</el-radio>
            <el-radio :label="2">无逾期用户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="芝麻分" style="width: 100%">
          <el-radio-group v-model="dataList.seedStr">
            <el-radio label="0">无限制</el-radio>
            <el-radio label="600-650"></el-radio>
            <el-radio label="650-700"></el-radio>
            <el-radio label="700分以上"></el-radio>
            <el-radio label="650分以上"></el-radio>
            <el-radio label="600分以上"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学历" style="width: 100%">
          <el-radio-group v-model="dataList.studyStr">
            <el-radio label="0">无限制</el-radio>
            <el-radio label="本科"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="信用卡" style="width: 100%">
          <el-radio-group v-model="dataList.creditStr">
            <el-radio label="0">无限制</el-radio>
            <el-radio label="2000以上"></el-radio>
            <el-radio label="1万以上"></el-radio>
            <el-radio label="3万以上"></el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-for="item in dataList.taskList">
          <el-form-item :label="item.name" style="width: 100%">
            <el-radio-group v-model="item.autoOnline">
              <el-radio :label="0">不要量</el-radio>
              <el-radio :label="1">要量时间</el-radio>
            </el-radio-group>
            <el-time-select
              v-if="item.autoOnline == 1"
              style="margin-left: 20px; width: 120px"
              v-model="item.autoOnlineTime"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:30',
              }"
              placeholder="选择时间"
            />
            <span style="margin-left: 20px" v-if="item.autoOnline == 1">-</span>
            <el-time-select
              v-if="item.autoOnline == 1"
              style="margin-left: 20px; width: 120px"
              v-model="item.autoOfflineTime"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '24:00',
              }"
              placeholder="选择时间"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="对接方式">
            <el-radio-group v-model="dataList.bankStatus">
              <el-radio :label="0">CRM</el-radio>
              <el-radio :label="1">API</el-radio>
            </el-radio-group>
          </el-form-item>
          <div style="display: flex" v-if="dataList.bankStatus">
            <el-form-item label="对接标识" style="width: 218px">
              <el-input v-model="dataList.bankSign"></el-input>
            </el-form-item>
            <el-form-item label="实现类" style="width: 218px">
              <el-input v-model="dataList.bankClass"></el-input>
            </el-form-item>
            <el-form-item label="渠道标识" style="width: 218px">
              <el-input v-model="dataList.channelSign"></el-input>
            </el-form-item>
          </div>
        </div>
        <p style="color: red; padding-left: 100px">
          注: API模式请先在沟通群或商务获取对接标识和实现类 (请勿随意修改)
        </p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUpdate">确 定</el-button>
      </span>
    </el-dialog>
    <el-table
      :data="tableData"
      style="width: 100%"
      :stripe="true"
      :row-key="getRowKey"
      :expand-row-keys="currentExpandIds"
      row-class-name="row-expand-unhas"
    >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div v-show="scope.row.showAllOCPCFlag" class="do-hidden-three">
                {{ scope.row.citiesState }}
              </div>
              <div v-show="scope.row.showAllRegionFlag" class="do-hidden-three">
                <span
                  v-for="(item, index) in scope.row.privce"
                  style="margin-right: 3px"
                  :key="index"
                >
                  <!-- <span>{{ item.name }}:</span> -->
                  <span>{{ item.city }}:</span>
                  <em>{{ item.pushNum }}/{{ item.limitNum }}</em>
                </span>
              </div>
              <div v-show="scope.row.showExposurePrice" class="do-hidden-three">
                <span
                  v-for="(item, index) in scope.row.privce"
                  style="margin-right: 3px"
                  :key="index"
                >
                  <!-- <span>{{ item.name }}:{{ item.exposurePrice }}</span> -->
                  <span>{{ item.city }}:{{ item.exposurePrice }}</span>
                </span>
              </div>
              <div v-show="scope.row.showPrice" class="do-hidden-three">
                <span
                  v-for="(item, index) in scope.row.privce"
                  style="margin-right: 3px"
                  :key="index"
                >
                  <span>{{ item.city }}:{{ item.price }}</span>
                  <!-- <span>{{ item.name }}:{{ item.price }}</span> -->
                </span>
              </div>
            </template>
          </el-table-column>
      <el-table-column prop="userName" label="状态">
        <template slot-scope="scope">
          <el-switch
            @change="changeState(scope.row, 3)"
            style="display: block"
            v-model="scope.row.taskState"
            active-color="#13ce66"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="子计划名称" prop="institutionName" width="160">
      </el-table-column>
      <el-table-column label="主条件" prop="condValue" width="140">
      </el-table-column>
      <el-table-column label="副条件" prop="otherValue" width="220">
      </el-table-column>
      <el-table-column label="当前投放城市" prop="city" width="120">
        <template slot-scope="scope">
          <div
            v-if="!scope.row.city"
            style="color: rgb(217, 48, 37); text-align: center"
          >
            当前暂无进量城市
          </div>
          <div
            v-if="scope.row.city.split('|').length > 30"
            style="cursor: pointer"
          >
            <div
              v-show="!scope.row.showAllOCPCFlag"
              @click="showAllRowRegion(scope.row, 1)"
            >
              <div
                v-for="(item, index) in scope.row.city.split('|').slice(0, 5)"
                :key="index"
              >
                {{ item }}
              </div>
              <div>...</div>
            </div>
            <div
              @click="hiddenAllRow(scope.row)"
              v-show="scope.row.showAllOCPCFlag"
            >
              <div
                v-for="(item, index) in scope.row.city.split('|').slice(0, 5)"
                :key="index"
              >
                {{ item }}
              </div>
              <div>...</div>
            </div>
          </div>
          <div v-else>
            <div v-if="scope.row.city.split('|').length < 6">
              <div
                v-for="(item, index) in scope.row.city.split('|')"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div v-else>
              <el-tooltip placement="right">
                <div slot="content">
                  <div
                    v-for="(item, index) in scope.row.city.split('|')"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
                <div>
                  <div
                    v-for="(item, index) in scope.row.city
                      .split('|')
                      .slice(0, 5)"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                  <div>...</div>
                </div>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="投放计划" prop="putCityPlan" width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.putCityPlan">
            <div
              v-if="scope.row.putCityPlan.length > 30"
              style="cursor: pointer"
            >
              <div
                v-show="!scope.row.showAllRegionFlag"
                @click="showAllRowRegion(scope.row, 2)"
              >
                <div
                  v-for="(item, index) in scope.row.putCityPlan.slice(0, 5)"
                  :key="index"
                >
                  <span>{{ item.city }}:</span>
                  <em>{{ item.pushNum }}/{{ item.limitNum }}</em>
                </div>
                <div>...</div>
              </div>
              <div
                @click="hiddenAllRow(scope.row)"
                v-show="scope.row.showAllRegionFlag"
              >
                <div
                  v-for="(item, index) in scope.row.putCityPlan.slice(0, 5)"
                  :key="index"
                >
                  <span>{{ item.city }}:</span>
                  <em>{{ item.pushNum }}/{{ item.limitNum }}</em>
                </div>
                <div>...</div>
              </div>
            </div>
            <div v-else>
              <div v-if="scope.row.putCityPlan.length < 6">
                <div
                  v-for="(item, index) in scope.row.putCityPlan"
                  :key="index"
                >
                  <span>{{ item.city }}:</span>
                  <em>{{ item.pushNum }}/{{ item.limitNum }}</em>
                </div>
              </div>
              <div v-else>
                <el-tooltip placement="right">
                  <div slot="content">
                    <div
                      v-for="(item, index) in scope.row.putCityPlan"
                      :key="index"
                    >
                      <span>{{ item.city }}:</span>
                      <em>{{ item.pushNum }}/{{ item.limitNum }}</em>
                    </div>
                  </div>
                  <div>
                    <div
                      v-for="(item, index) in scope.row.putCityPlan.slice(0, 5)"
                      :key="index"
                    >
                      <span>{{ item.city }}:</span>
                      <em>{{ item.pushNum }}/{{ item.limitNum }}</em>
                    </div>
                    <div>...</div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="广告出价" prop="advBid"> </el-table-column>
      <el-table-column
        label="广告转化数"
        prop="num"
        width="100"
      ></el-table-column>
      <el-table-column label="修改时间" prop="updateTime" width="150">
      </el-table-column>
      <el-table-column label="设置" width="150" fixed="right">
        <template slot-scope="scope">
          <div style="display: flex; flex-wrap: wrap">
            <el-button
              size="small"
              type="primary"
              @click="getInstEcho(scope.row)"
              >编辑</el-button
            >
            <el-button
              @click="changeState(scope.row, 2)"
              size="small"
              type="success"
              v-if="scope.row.status == 0"
              >启用
            </el-button>
            <el-button
              @click="changeState(scope.row, 2)"
              size="small"
              type="danger"
              v-if="scope.row.status == 1"
              >冻结
            </el-button>
            <el-button
              @click="changeState(scope.row, 1)"
              size="small"
              type="danger"
              style="margin-left: 0; margin-top: 10px"
              v-if="scope.row.status == 0"
              >删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="queryList.pageIdx"
      :limit.sync="queryList.pageSize"
      @pagination="pagination"
    />
  </div>
</template>
  
  <script>
import {
  get_inst_list,
  post_inst_save,
  region_adress,
  get_inst_echo,
  get_code_list,
  get_state_operate,
} from "../../api/SubplanAllocation";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    var checkMainCondStr = (rule, value, callback) => {
      if (this.dataList.type && this.dataList.type !== 7) {
        if (!this.dataList.mainCondStr) {
          callback(new Error("请选择主条件详情"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      getRowKey(row) {
        return row.id;
      },
      total: 0,
      title: "新增子计划",
      tableData: [],
      region: [],
      copyDataList: {},
      optionType: [],
      dataList: {
        advBid: "",
        institutionName: "",
        ageStr: "",
        moneyStr: "0",
        type: "",
        seedStr: "0",
        studyStr: "0",
        creditStr: "0",
        bankSign: "",
        bankStatus: 0,
        bankClass: "",
        channelSign: "",
        mainCondStr: "",
        type: "",
        region: "",
        overdueState: 0,
        taskList: [
          {
            autoOfflineTime: "24:00",
            autoOnline: 1,
            autoOnlineTime: "00:00",
            type: 1,
            name: "周一",
          },
          {
            autoOfflineTime: "24:00",
            autoOnline: 1,
            autoOnlineTime: "00:00",
            type: 2,
            name: "周二",
          },
          {
            autoOfflineTime: "24:00",
            autoOnline: 1,
            autoOnlineTime: "00:00",
            type: 3,
            name: "周三",
          },
          {
            autoOfflineTime: "24:00",
            autoOnline: 1,
            autoOnlineTime: "00:00",
            type: 4,
            name: "周四",
          },
          {
            autoOfflineTime: "24:00",
            autoOnline: 1,
            autoOnlineTime: "00:00",
            type: 5,
            name: "周五",
          },
          {
            autoOfflineTime: "24:00",
            autoOnline: 1,
            autoOnlineTime: "00:00",
            type: 6,
            name: "周六",
          },
          {
            autoOfflineTime: "24:00",
            autoOnline: 1,
            autoOnlineTime: "00:00",
            type: 7,
            name: "周日",
          },
        ],
      },
      rules: {
        institutionName: [
          { required: true, message: "请输入子计划名称", trigger: "blur" },
        ],
        advBid: [{ required: true, message: "请输入出价", trigger: "blur" }],
        region: [
          { required: true, message: "请选择投放地区", trigger: "change" },
        ],
        ageStr: [{ required: true, message: "请选择年龄", trigger: "change" }],
        type: [{ required: true, message: "请选择主条件", trigger: "change" }],
        mainCondStr: [{ validator: checkMainCondStr, trigger: "change" }],
      },
      dialogTableVisible: false,
      queryList: {
        pageIdx: 1,
        pageSize: 10,
        orderByNum: 0,
        orderByPrice: 0,
        keyword: "",
        status: 1,
      },
      options: [],
      props: {
        multiple: true,
        value: "name",
        label: "name",
        children: "childrenList",
      },
      regionsPrivce: [],
      areaConfigHide: true,
      taskList: [],
      mainCondList: [],
      currentExpandIds: [],
    };
  },
  watch: {
    "queryList.keyword"(newValue) {
      if (!newValue) {
        this.queryList.pageIdx = 1;
        this.getList();
      }
    },
    dialogTableVisible(newval) {
      if (!newval) {
        this.$refs.dataList.resetFields();
        this.areaConfigHide = true
      }
    },
  },
  mounted() {
    this.copyDataList = JSON.stringify(this.dataList);
    region_adress().then((res) => {
      this.options = res.data;
    });
    get_code_list().then((res) => {
      this.optionType = res.data;
    });
    this.getList();
  },
  methods: {
    hiddenAllRow(row) {
      console.log(2);
      row.showAllOCPCFlag = false;
      row.showAllRegionFlag = false;
      row.showPrice = false;
      row.showExposurePrice = false;
      this.currentExpandIds = [];
    },
    showAllRowRegion(row, type) {
      console.log(1);
      this.currentExpandIds = [row.id];
      this.tableData.forEach((item) => {
          item.showAllOCPCFlag = false;
          item.showAllRegionFlag = false;
          item.showPrice = false;
          item.showExposurePrice = false;
        if (type === 1) {
          row.showAllOCPCFlag = true;
          row.showAllRegionFlag = false;
          row.showPrice = false;
          row.showExposurePrice = false;
        } else if (type === 2) {
          row.showAllOCPCFlag = false;
          row.showAllRegionFlag = true;
          row.showPrice = false;
          row.showExposurePrice = false;
        }
      });
    },
    changeMainCond(val) {
      this.dataList.mainCondStr = "";
      if (val === this.dataList.type) {
        this.optionType.forEach((item) => {
          if (item.type === val) {
            this.mainCondList = item.valueList;
          }
        });
      }
    },
    changeState(data, type) {
      const dto = {};
      dto.type = type;
      dto.id = data.id;
      if (type === 2) {
        this.$confirm(
          data.status == 1 ? "是否确认冻结？" : "是否确认启用？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            dto.value = data.status == 1 ? 0 : 1;
            get_state_operate(dto).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: data.status == 1 ? "冻结成功" : "启用成功",
                  type: "success",
                });
              if (this.tableData.length === 1 && this.queryList.pageIdx !== 1) {
                this.queryList.pageIdx--;
              }
                this.getList();
              }
            });
          })
          .catch(() => {});
      } else if (type === 1) {
        this.$confirm("您确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            get_state_operate(dto).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: `删除成功`,
                  type: "success",
                });
              if (this.tableData.length === 1 && this.queryList.pageIdx !== 1) {
                this.queryList.pageIdx--;
              }
                this.getList();
              }
            });
          })
          .catch(() => {});
      } else {
        dto.value = data.taskState;
        get_state_operate(dto)
          .then((res) => {
            if (res.code == 200) {
              this.getList();
            } else {
              data.taskState = data.taskState ? 0 : 1;
            }
          })
          .catch(() => {
            data.taskState = data.taskState ? 0 : 1;
          });
      }
    },
    changeCondition() {
      this.queryList.pageIdx = 1;
      this.getList();
    },
    addUpdate() {
      this.$refs.dataList.validate((valid) => {
        if (valid) {
          this.dataList.putCityList = this.regionsPrivce;
          let c = JSON.stringify(this.dataList.region);
          if (this.dataList.region != "") {
            for (let i = 0; i < this.dataList.region.length; i++) {
              let text = "";
              for (let j = 0; j < 2; j++) {
                text = `${this.dataList.region[i][0]}-${this.dataList.region[i][1]}`;
              }
              this.dataList.region[i] = text;
            }
            this.dataList.region = this.dataList.region.join("|");
          } else {
            this.dataList.region = "";
          }
          const regionArr = this.dataList.region.split("|");
          this.dataList.region = "";
          regionArr.forEach((item) => {
            this.dataList.region = this.dataList.region + item + "|";
          });
          this.dataList.region = this.dataList.region.slice(0, -1);
          post_inst_save(this.dataList)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: `${this.title}成功`,
                  type: "success",
                });
                this.dialogTableVisible = false;
                this.getList();
                this.regionsPrivce = [];
              } else {
                this.dataList.region = JSON.parse(c);
              }
            })
            .catch((res) => {
              this.dataList.region = JSON.parse(c);
            });
        } else {
        }
      });
    },
    getInstEcho(data) {
      this.title = "编辑子计划";
      get_inst_echo({
        id: data.id,
      }).then((res) => {
        if (res.code === 200) {
          this.dataList = res.data;
          this.dataList.taskList.forEach((item) => {
            switch (item.type) {
              case 1:
                item.name = "周一";
                break;
              case 2:
                item.name = "周二";
                break;
              case 3:
                item.name = "周三";
                break;
              case 4:
                item.name = "周四";
                break;
              case 5:
                item.name = "周五";
                break;
              case 6:
                item.name = "周六";
                break;
              case 7:
                item.name = "周日";
                break;
            }
          });
          this.optionType.forEach((item) => {
            if (item.type === this.dataList.type) {
              this.mainCondList = item.valueList;
            }
          });
          this.regionsPrivce = res.data.putCityList;
          this.dialogTableVisible = true;
          this.taskList = res.data.taskList;
          let diqu = res.data.region.split("|");
          for (var i = 0; i < diqu.length; i++) {
            diqu[i] = diqu[i].split("-");
          }
          this.dataList.region = diqu;
        }
      });
    },
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    regionChange(val) {
      if (val.length > this.regionsPrivce.length) {
        var arr1 = [];
        var arr2 = [];
        val.forEach((item) => {
          arr1.push(item[1]);
        });
        this.regionsPrivce.forEach((item) => {
          arr2.push(item.city);
        });
        this.getArrDifference(arr1, arr2).forEach((item) => {
          this.regionsPrivce.push({
            city: item,
            limitNum: 0,
          });
        });
      } else {
        var e = [];
        for (let i = 0; i < val.length; i++) {
          for (let j = 0; j < this.regionsPrivce.length; j++) {
            if (val[i][1] == this.regionsPrivce[j].city) {
              e.push(this.regionsPrivce[j]);
            }
          }
        }
        this.regionsPrivce = e;
      }
    },
    // 新增子计划
    addDialog() {
      this.dialogTableVisible = true;
      this.title = "新增子计划";
      this.dataList = JSON.parse(this.copyDataList);
      this.regionsPrivce = [];
    },
    // 转化数排序
    changNum() {
      this.queryList.orderByPrice = 0;
      switch (this.queryList.orderByNum) {
        case 0:
          this.queryList.orderByNum = 1;
          break;
        case 1:
          this.queryList.orderByNum = 2;
          break;
        case 2:
          this.queryList.orderByNum = 0;
          break;
      }
      this.getList();
    },
    changNumUp() {
      this.queryList.orderByPrice = 0;
      switch (this.queryList.orderByNum) {
        case 0:
          this.queryList.orderByNum = 1;
          break;
        case 1:
          this.queryList.orderByNum = 0;
          break;
        case 2:
          this.queryList.orderByNum = 1;
          break;
      }
      this.getList();
    },
    changNumDown() {
      this.queryList.orderByPrice = 0;
      switch (this.queryList.orderByNum) {
        case 0:
          this.queryList.orderByNum = 2;
          break;
        case 1:
          this.queryList.orderByNum = 2;
          break;
        case 2:
          this.queryList.orderByNum = 0;
          break;
      }
      this.getList();
    },
    // 出价排序
    changPrice() {
      this.queryList.orderByNum = 0;
      switch (this.queryList.orderByPrice) {
        case 0:
          this.queryList.orderByPrice = 1;
          break;
        case 1:
          this.queryList.orderByPrice = 2;
          break;
        case 2:
          this.queryList.orderByPrice = 0;
          break;
      }
      this.getList();
    },
    changPriceUp() {
      this.queryList.orderByNum = 0;
      switch (this.queryList.orderByPrice) {
        case 0:
          this.queryList.orderByPrice = 1;
          break;
        case 1:
          this.queryList.orderByPrice = 0;
          break;
        case 2:
          this.queryList.orderByPrice = 1;
          break;
      }
      this.getList();
    },
    changPriceDown() {
      this.queryList.orderByNum = 0;
      switch (this.queryList.orderByPrice) {
        case 0:
          this.queryList.orderByPrice = 2;
          break;
        case 1:
          this.queryList.orderByPrice = 2;
          break;
        case 2:
          this.queryList.orderByPrice = 0;
          break;
      }
      this.getList();
    },
    changeCond() {
      this.queryList.pageIdx = 1;
      this.getList();
    },
    // 获取列表数据
    getList() {
      get_inst_list(this.queryList).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.tableData.forEach((doItem) => {
            if (doItem) {
              doItem.privce = doItem.putCityPlan;
              let cityState = 0;
              let citiesState = "";
              doItem.privce.forEach((item) => {
                cityState += item.state;
                if (item.state !== 0) {
                  citiesState = citiesState + "|" + item.city;
                }
              });
              doItem.showCityState =
                doItem.privce.filter((item) => item.state === 0).length !== 0;
              // if (!cityState) {
              //   doItem.showCityState = true;
              // }
              citiesState = citiesState.slice(1, -1);
              doItem.citiesState = citiesState;
              doItem.disabledRegion = true;
              doItem.disabledPrivce = true;
              doItem.showAllOCPCFlag = false;
              doItem.showAllRegionFlag = false;
              doItem.showPrice = false;
              doItem.showExposurePrice = false;
            }
          });
        }
      });
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.queryList.pageIdx = params.page;
      } else {
        this.queryList.pageIdx = 1;
        this.queryList.pageSize = params.limit;
      }
      this.getList();
    },
  },
};
</script>
  
<style lang="less" scoped>
::v-deep .row-expand-unhas .el-table__expand-column {
  pointer-events: none;
}

::v-deep .row-expand-unhas .el-table__expand-column .el-icon {
  visibility: hidden;
}
::v-deep .el-switch {
  height: 22px;
}
.show-hide {
  width: 80%;
  text-align: center;
  font-size: 16px;
  color: rgb(99, 176, 255);

  span {
    cursor: pointer;
  }
}
.hidden-line-5 {
  display: -webkit-box;
  overflow: hidden;
  /*超出隐藏*/
  text-overflow: ellipsis;
  /*隐藏后添加省略号*/
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
::v-deep .el-date-editor .el-range-separator {
  width: 9%;
}
::v-deep .el-date-editor--daterange.el-input__inner {
  width: 250px !important;
}
::v-deep .el-select {
  width: 150px;
  margin-right: 10px;
}
::v-deep .el-radio {
  margin-right: 10px;
}

::v-deep thead th {
  color: #000;
  background: rgb(250, 250, 250);
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-pagination {
  text-align: center;
}

::v-deepinput[type="number"] {
  -moz-appearance: textfield !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .el-table td {
  padding: 2px 0;
}
</style>