import request from './request.js';

// 列表
const get_inst_list = (params) => {
    return request({
      url: '/city/list',
      method: 'GET',
      params
    })
  }  

  // 回显
  const get_inst_echo = (params) => {
      return request({
        url: '/city/echo',
        method: 'GET',
        params
      })
    }
    // 主条件枚举
  const get_code_list = () => {
    return request({
      url: '/city/code/list',
      method: 'GET',
      
    })
  } 

  //保存
  const post_inst_save = (data) => {
    return request({
      url: '/city/save',
      method: 'POST',
      data
    })
  }

   //删除、冻结开关、定时任务状态开关
   const get_state_operate = (data) => {
    return request({
      url: '/city/operate',
      method: 'POST',
      data
    })
  }

  //获取省市地区列表
  const region_adress = (data) => {
  return request({
      url: '/zoom/find-half-adress',
      method: 'get',
      params: data
  })
}
  
  export {
    get_inst_list,
    post_inst_save,
    region_adress,
    get_inst_echo,
    get_code_list,
    get_state_operate
  }